import { OsTable } from '@/components';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import { OsTableOption, OsTableColumn, RowOperation } from '@/components/os-table/os-table';
import { CustomColumnMixin } from '@/mixins/custom-column';
import { DialogMixin } from '@/mixins/dialog';
import {
  NumberAssignmentTypeEnum,
  PrepressChangeColorCraftsEnum,
  PrepressLayoutCraftsEnum,
  ProjectItemTypeEnum,
  StringAssignmentTypeEnum
} from '@/resource/enum';
import {
  FieldOption,
  FieldOptionList,
  ProjectProductDetail,
  ProjectProductList,
  SaveProjectProduct
} from '@/resource/model';
import { decimalToNumber6, messageErrors, translation } from '@/utils';

import { Message, Table } from 'element-ui';
import { mixins } from 'vue-class-component';
import { Component, InjectReactive, Prop, Watch } from 'vue-property-decorator';

import { BatchSetupData } from '@/views/dialogs';
import EditProduct from './edit-product/edit-product.vue';

import { projectProductService } from '@/api';
import { Decimal } from 'decimal.js';
import { ApiError } from '@/api/axios';
import { cloneDeep } from 'lodash-es';
// const EditProduct = (): any => import(/* webpackChunkName: "edit-project-product" */ './edit-product/edit-product.vue');
@Component({
  components: {
    BatchSetupData,
    EditProduct
  }
})
export default class BatchRevision extends mixins(DialogMixin, CustomColumnMixin) {
  /**
   * 要改稿的数据
   */
  @Prop({
    required: false,
    type: Array,
    default: () => {
      return [];
    }
  })
  public editList!: Array<ProjectProductList>;

  @InjectReactive()
  public readonly projectCode!: string;

  @InjectReactive()
  public readonly projectId!: number;

  public dialogVisible = false;

  public editVisible = false;
  public editRows: ProjectProductDetail = {} as ProjectProductDetail;

  public pageTotal = 0;
  public tableOption: OsTableOption<ProjectProductDetail> = {
    height: '400px',
    loading: false,
    data: [],
    fit: true
  };
  /**
   * table上方的表格操作配置
   */
  public operationOptions: Array<OperationOption> = [
    {
      type: 'primary',
      slot: 'start',
      label: 'project.batchRevisionItem',
      disabled: true,
      operationType: 'batchRevisionItem',
      handleClick: (): void => {
        this.openDialog();
      }
    },
    {
      type: 'danger',
      slot: 'start',
      label: 'batchSetupData.remove',
      disabled: true,
      operationType: 'delete',
      handleClick: (): void => {
        this.batchDelete();
      }
    }
  ];
  /**
   * table行的操作配置
   */
  public rowOperationOptions: RowOperation<ProjectProductDetail> = {
    fixed: 'right',
    width: '80px',
    operations: [
      {
        operationType: 'edit',
        type: 'text',
        label: 'button.edit',
        icon: 'el-icon-edit',
        permissionCode: 'project:item:edit',
        handleClick: (row: ProjectProductDetail): void => {
          this.openEditDialog(row);
        }
      }
    ]
  };
  /**
   * 表格列配置
   */
  public columnOption: Array<OsTableColumn<ProjectProductDetail>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true,
      fixed: true
    },
    {
      prop: 'itemCode',
      label: 'projectProduct.itemCode',
      minWidth: '200px',
      showOverflowTooltip: true,
      fixed: true
    },
    {
      prop: 'itemType',
      label: 'projectProduct.itemType',
      minWidth: '100px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        const itemType = (row as ProjectProductList).itemType;
        return translation(`projectItemType.${ProjectItemTypeEnum[itemType]}`);
      }
    },
    {
      prop: 'pointName',
      label: 'projectProduct.pointName',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'platformProductName',
      label: 'projectProduct.platformProduct',
      showOverflowTooltip: true,
      minWidth: '250px'
    },
    {
      prop: 'customerProductName',
      label: 'projectProduct.customerProduct',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'backendCrafts',
      label: 'projectProduct.backendCrafts',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'count',
      label: 'projectProduct.count',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'visibleHeight',
      label: 'projectProduct.visibleSize',
      showOverflowTooltip: true,
      minWidth: '150px',
      formatter: (row: Object): string => {
        return `${(row as ProjectProductList).visibleWidth} × ${(row as ProjectProductList).visibleHeight}`;
      }
    },
    {
      prop: 'finishHeight',
      label: 'projectProduct.finishSize',
      showOverflowTooltip: true,
      minWidth: '150px',
      formatter: (row: Object): string => {
        return `${(row as ProjectProductList).finishWidth} × ${(row as ProjectProductList).finishHeight}`;
      }
    },
    {
      prop: 'prepressPicture',
      label: 'projectProduct.prepressPicture',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'prepressRemark',
      label: 'projectProduct.prepressRemark',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'prepressCrafts',
      label: 'projectProduct.prepressCrafts',
      minWidth: '180px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        if (!(row as ProjectProductList).prepressCrafts) {
          return '--';
        }
        if ((row as ProjectProductList).itemType === ProjectItemTypeEnum.changeColor) {
          return translation(
            `prepressChangeColorCrafts.${PrepressChangeColorCraftsEnum[(row as ProjectProductList).prepressCrafts]}`
          );
        }
        return translation(
          `prepressLayoutCrafts.${PrepressLayoutCraftsEnum[(row as ProjectProductList).prepressCrafts]}`
        );
      }
    },
    {
      prop: 'remark',
      label: 'projectProduct.remark',
      minWidth: '150px',
      showOverflowTooltip: true
    }
  ];
  public selectedRows: Array<ProjectProductDetail> = [];
  /**
   * 字段批量修改配置项
   */
  public fieldOptions: Array<FieldOptionList<ProjectProductDetail>> = [
    {
      fieldName: 'visibleWidth',
      fieldLabel: translation('projectProduct.visibleWidth'),
      disabled: false,
      fieldType: 'number'
    },
    {
      fieldName: 'visibleHeight',
      fieldLabel: translation('projectProduct.visibleHeight'),
      disabled: false,
      fieldType: 'number'
    },
    {
      fieldName: 'finishWidth',
      fieldLabel: translation('projectProduct.finishWidth'),
      disabled: false,
      fieldType: 'number'
    },
    {
      fieldName: 'finishHeight',
      fieldLabel: translation('projectProduct.visibleHeight'),
      disabled: false,
      fieldType: 'number'
    },
    {
      fieldName: 'topPocketSize',
      fieldLabel: translation('projectProduct.topPocketSize'),
      disabled: false,
      fieldType: 'number'
    },
    {
      fieldName: 'bottomPocketSize',
      fieldLabel: translation('projectProduct.bottomPocketSize'),
      disabled: false,
      fieldType: 'number'
    },
    {
      fieldName: 'pointName',
      fieldLabel: translation('projectProduct.pointName'),
      disabled: false,
      fieldType: 'string'
    },
    {
      fieldName: 'remark',
      fieldLabel: translation('projectProduct.remark'),
      disabled: false,
      fieldType: 'string'
    },
    {
      fieldName: 'prepressPicture',
      fieldLabel: translation('projectProduct.prepressPicture'),
      disabled: false,
      fieldType: 'string'
    },
    {
      fieldName: 'prepressRemark',
      fieldLabel: translation('projectProduct.prepressPicture'),
      disabled: false,
      fieldType: 'string'
    },
    {
      fieldName: 'prepressSubmitCount',
      fieldLabel: translation('projectProduct.prepressSubmitCount'),
      disabled: false,
      fieldType: 'number'
    },
    {
      fieldName: 'reeditExplain',
      fieldLabel: translation('projectProduct.reeditExplain'),
      disabled: false,
      fieldType: 'string'
    }
  ];
  public created(): void {}

  public dialogOpen(): void {
    const ids = this.editList.map(x => x.id);
    projectProductService
      .getBatchById(ids)
      .then(results => {
        results.forEach(x => {
          x.oldProjectProduct = cloneDeep(x);
          x.reeditExplain = '';
          x.prepressSubmitCount = 1;
        });
        this.tableOption.data = results;
      })
      .catch(error => {
        if (error) messageErrors(error);
      });
  }

  public dialogClosed(): void {
    this.clearSelection();
  }

  public openDialog(): void {
    if (this.selectedRows.length === 0) {
      Message.warning(translation('batchSetupData.selectDataTip'));
      return;
    }
    // 打开字段修改
    this.dialogVisible = true;
  }

  public batchDelete(): void {
    if (this.selectedRows.length === 0) {
      Message.warning(translation('batchSetupData.selectDataTip'));
      return;
    }
    if (this.selectedRows.length === this.tableOption.data.length) {
      Message.warning(translation('common.keepDataTip'));
      return;
    }
    this.tableOption.data = this.tableOption.data.filter(data => !this.selectedRows.includes(data));
    this.clearSelection();
  }

  /**
   * 表格勾选事件
   */
  public handleSelectionChange(selectedData: Array<ProjectProductDetail>): void {
    this.selectedRows = [...selectedData];
  }

  /**
   * 单个修改赋值
   * @param field
   */

  public itemAssignment(rowData: ProjectProductDetail): void {
    this.tableOption.data.forEach(item => {
      if (item.id === rowData.id) {
        Object.assign(item, rowData);
      }
    });
    this.clearSelection();
  }

  /**
   * 批量修改赋值
   */
  public setData(field: Array<FieldOption>): void {
    const errors: Array<ApiError> = [];
    this.selectedRows.forEach(item => {
      const entries = new Map(Object.entries(item));
      let message = '';
      const isEditBaseInfo = item.installCount === 0 && item.deliveryCount === 0 && item.invoiceCount === 0;
      field.forEach(cur => {
        if (
          !isEditBaseInfo &&
          ['visibleHeight', 'visibleWidth', 'finishWidth', 'finishHeight', 'pointName', 'remark'].includes(
            cur.fieldName
          )
        ) {
          message = '已有下游关联数据，基本信息不允许修改';
          return;
        }
        if (cur.fieldType === 'string') {
          switch (cur.assignmentType) {
            case StringAssignmentTypeEnum.update:
              entries.set(cur.fieldName, cur.fieldValue);
              break;
            case StringAssignmentTypeEnum.increase:
              entries.set(cur.fieldName, `${entries.get(cur.fieldName) || ''}${cur.fieldValue}`);
              break;
            default:
              break;
          }
        } else if (cur.fieldType === 'number') {
          switch (cur.assignmentType) {
            case NumberAssignmentTypeEnum.equal:
              entries.set(cur.fieldName, cur.fieldValue);
              break;
            case NumberAssignmentTypeEnum.add:
              entries.set(cur.fieldName, entries.get(cur.fieldName) + cur.fieldValue);
              break;
            case NumberAssignmentTypeEnum.sub:
              entries.set(cur.fieldName, entries.get(cur.fieldName) - cur.fieldValue);
              break;
            default:
              break;
          }
        }
        // } else if (cur.fieldType === 'select') {
        //   switch (cur.assignmentType) {
        //     case SelectAssignmentTypeEnum.update:
        //       entries.set(cur.fieldName, cur.fieldValue);
        //       break;
        //     default:
        //       break;
        //   }
        // }
        Object.assign(item, Object.fromEntries(entries));
      });

      if (message) {
        errors.push({
          message: `【${item.itemCode}】: ${message} `,
          level: 1
        });
      }
      // 修改尺寸后同步财务信息
      this.calculateFinancialInfo(item);
    });
    if (errors.length > 0) {
      messageErrors(errors);
    }
    this.clearSelection();
    this.dialogVisible = false;
  }

  /**
   * 财务信息计算
   * @returns
   */
  public calculateFinancialInfo(rowData: ProjectProductDetail): ProjectProductDetail {
    // 计算单位面积
    rowData.unitArea =
      decimalToNumber6(new Decimal(rowData.finishWidth as number).mul(new Decimal(rowData.finishHeight as number))) /
      1000000;
    // 计算总面积
    rowData.totalArea = decimalToNumber6(
      new Decimal(rowData.unitArea as number).mul(new Decimal(rowData.count as number))
    );
    // 计算计价数量
    if (rowData.areaPriceFlag === 1) {
      // 计价数量等于计价面积
      rowData.priceCount = rowData.totalArea;
    } else {
      rowData.priceCount = rowData.count;
    }

    // 税额 = 单价 * (税率/100) * 计价数量
    rowData.taxAmount = decimalToNumber6(
      new Decimal(rowData.salePrice!)
        .mul(new Decimal(rowData.taxRate!))
        .div(new Decimal(100))
        .mul(new Decimal(rowData.priceCount!))
    );
    // 未税金额 = 单价 * 计价数量
    rowData.totalPrice = decimalToNumber6(new Decimal(rowData.salePrice!).mul(new Decimal(rowData.priceCount!)));
    // 含税金额 = 未税金额 +  税额
    rowData.amountIncludingTax = decimalToNumber6(
      new Decimal(rowData.totalPrice!).add(new Decimal(rowData.taxAmount!))
    );

    return rowData;
  }

  /**
   * 数据校验
   * @returns
   */
  public validate(): boolean {
    if (this.tableOption.data?.length === 0) {
      Message.warning(`${translation('没有可提交的数据')}`);
      return false;
    }
    const errors: Array<ApiError> = [];
    this.tableOption.data?.forEach(product => {
      let message = '';
      const {
        visibleWidth,
        visibleHeight,
        finishWidth,
        finishHeight,
        pointName,
        remark,
        prepressPicture,
        prepressRemark,
        prepressSubmitCount
      } = product as Partial<ProjectProductDetail>;
      if (!visibleWidth || visibleWidth <= 0 || !visibleHeight || visibleHeight <= 0) {
        message = `[${translation('projectProduct.inputVisibleSize')}]`;
      } else if (visibleWidth > finishWidth! || visibleHeight > finishHeight!) {
        // 可视尺寸不能大于完成尺寸
        message = message + `[${translation('prepress.verifyVisibleSize')}]`;
      }
      if (pointName!.length > 200) {
        // 点位名称超出长度
        message = message + `[点位名称：超出长度范围]`;
      }
      if (remark!.length > 200) {
        message = message + `[备注信息：超出长度范围]`;
      }

      if (!prepressPicture) {
        message = message + `[印前画面：未填写]`;
      } else if (prepressPicture.length > 200) {
        message = message + `[印前画面：超出长度范围]`;
      }
      if (!prepressRemark) {
        message = message + `[印前说明：未填写]`;
      } else if (prepressPicture!.length > 200) {
        message = message + `[印前说明：超出长度范围]`;
      }
      if (prepressPicture && prepressPicture.length > 200) {
        message = message + `[改稿说明：超出长度范围]`;
      }

      if (!prepressSubmitCount || prepressSubmitCount <= 0) {
        message = message + `[交稿数量：要求大于等于1]`;
      }

      message += this.validatePocketSize(product);

      if (message) {
        errors.push({
          message: `【${product.itemCode}】: ${message} `,
          level: 1
        });
      }
    });
    if (errors.length > 0) {
      messageErrors(errors);
      return true;
    }
    return false;
  }

  public validatePocketSize(product: ProjectProductDetail): string {
    let message = "";
    if (product.pocketFlag === 1) {
      if (product.topPocketSize < 0 && product.bottomPocketSize < 0) {
        message += message + `[口袋尺寸：不能全部为0]`;
      }

      if ((product.topPocketSize < 0 || product.topPocketSize > 999.9)) {
        message += message + `[上口袋尺寸：要求大于等于0且小于等于999.9]`;
      }

      if ((product.bottomPocketSize < 0 || product.bottomPocketSize > 999.9)) {
        message += message + `[下口袋尺寸：要求大于等于0且小于等于999.9]`;
      }
    } else {
      product.topPocketSize = 0;
      product.bottomPocketSize = 0;
    }

    return message;
  }

  /**
   * 修改日志组装
   * @returns
   */
  public handleChangeRecord(
    projectProductForm: Partial<ProjectProductDetail>,
    oldProjectProduct: Partial<ProjectProductDetail>
  ): Array<{
    propName: string;
    oldValue: any;
    newValue: any;
  }> {
    const changeRecord: Array<{
      propName: string;
      oldValue: any;
      newValue: any;
    }> = [];

    // 需要记录变更的属性
    const requiredRecordProps: Array<keyof SaveProjectProduct> = [
      'platformProductName',
      'backendCrafts',
      'finishHeight',
      'finishWidth',
      'prepressPicture',
      'prepressRemark',
      'remark',
      'visibleHeight',
      'visibleWidth',
      'pointName',
      'count',
      'prepressCrafts',
      'printingFlag',
      'backendFlag',
      'prepressFlag',
      'platformPrepressFlag',
      'installFlag',
      'logisticsFlag',
      'requiredDeliveryTime',
      'requiredArriveTime',
      'prepressSubmitCount',
      'areaPriceFlag',
      'priceUnitName',
      'priceCount',
      'salePrice',
      'priceIncludingTax',
      'taxRate',
      'totalPrice',
      'taxAmount',
      'amountIncludingTax',
      'saleUnitName',
      'unitArea',
      'totalArea',
      'reeditExplain'
    ];

    // 记录时要对flag做特殊处理
    const flags: Array<keyof SaveProjectProduct> = [
      'backendFlag',
      'installFlag',
      'prepressFlag',
      'printingFlag',
      'logisticsFlag',
      'platformPrepressFlag'
    ];
    // 记录时要对time做特殊处理
    // const times: Array<keyof SaveProjectProduct> = ['requiredArriveTime', 'requiredDeliveryTime'];

    for (const prop of requiredRecordProps) {
      if (projectProductForm[prop] === oldProjectProduct[prop]) {
        continue;
      }

      // 处理flag值的显示
      if (flags.includes(prop)) {
        changeRecord.push({
          propName: `projectProduct.${prop}`,
          newValue:
            projectProductForm[prop] === 1
              ? translation('projectProduct.flagOpen')
              : translation('projectProduct.flagClose'),
          oldValue:
            oldProjectProduct[prop] === 1
              ? translation('projectProduct.flagOpen')
              : translation('projectProduct.flagClose')
        });
        continue;
      }
      if (prop === 'areaPriceFlag') {
        changeRecord.push({
          propName: `projectProduct.${prop}`,
          newValue: projectProductForm[prop] === 1 ? translation('common.yes') : translation('common.no'),
          oldValue: oldProjectProduct[prop] === 1 ? translation('common.yes') : translation('common.no')
        });
        continue;
      }

      // // 处理time值的显示
      // if (times.includes(prop)) {
      //   changeRecord.push({
      //     propName: `projectProduct.${prop}`,
      //     newValue: dateFormat(projectProductForm[prop] as string),
      //     oldValue: dateFormat(oldProjectProduct[prop] as string)
      //   });
      //   continue;
      // }

      // 处理印前工艺值的显示
      if (prop === 'prepressCrafts') {
        const craftsEnum =
          projectProductForm.itemType === ProjectItemTypeEnum.changeColor
            ? PrepressChangeColorCraftsEnum
            : PrepressLayoutCraftsEnum;
        const i18nKey =
          projectProductForm.itemType === ProjectItemTypeEnum.changeColor
            ? 'prepressChangeColorCrafts'
            : 'prepressLayoutCrafts';
        changeRecord.push({
          propName: `projectProduct.${prop}`,
          newValue: translation(`${i18nKey}.${craftsEnum[projectProductForm[prop]!]}`),
          oldValue: translation(`${i18nKey}.${craftsEnum[oldProjectProduct[prop]!]}`)
        });
        continue;
      }

      changeRecord.push({
        // 这里用projectProduct拼接，是因为这些字段的国际化key，定义在projectProduct里
        propName: `projectProduct.${prop}`,
        newValue: projectProductForm[prop],
        oldValue: oldProjectProduct[prop]
      });
    }
    return changeRecord;
  }

  public async onSubmit(): Promise<void> {
    this.setLoading(true);
    // 校验数据
    if (this.validate()) {
      this.setLoading(false);
      return;
    }
    const reedit = cloneDeep(this.tableOption.data);

    reedit.forEach(item => {
      item.changeLog = JSON.stringify(this.handleChangeRecord(item, item.oldProjectProduct));
      // item.projectCode = this.projectCode;
      // item.projectId = this.projectId;
    });

    try {
      this.setLoading(true);
      await projectProductService.batchReedit(reedit);
      Message.success(translation('operationRes.editSuccess'));
      this.$emit('edit-success');
      this.closeDialog();
    } catch (error) {
      if (error) messageErrors(error);
    } finally {
      this.setLoading(false);
    }
    this.syncedVisible = false;
  }

  /**
   * 清空选中状态
   */
  public clearSelection(): void {
    ((this.$refs.batchRevisionTable as OsTable).tableRef as Table).clearSelection();
  }

  /**
   * 编辑
   */
  private openEditDialog(rowData: ProjectProductDetail): void {
    this.editVisible = true;
    this.editRows = rowData;
  }
  @Watch('selectedRows')
  private handleSelectedChanged(value: Array<ProjectProductList>): void {
    const dynamicBtnTypes: Array<string> = ['batchRevisionItem', 'delete'];
    this.operationOptions.forEach(x => {
      if (dynamicBtnTypes.includes(x.operationType)) {
        x.disabled = value.length === 0;
      }
    });
  }
}
