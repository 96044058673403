import { ArchiveStatusEnum } from './../../../../../resource/enum/close-status';
import { Component, Emit, InjectReactive, Prop, Watch } from 'vue-property-decorator';
import { OsTable, OsQueryPlan } from '@/components';
import { OsTableColumn, OsTableOption, RowOperation } from '@/components/os-table/os-table';
import { ImportRes, ProjectProductList, ProjectProductListQuery } from '@/resource/model';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import {
  dateFormat,
  debounce,
  downloadFileByBlob,
  handleImportError,
  messageError,
  messageErrors,
  showWarningConfirm,
  translation
} from '@/utils';
import { Message } from 'element-ui';
import { FileService, projectProductService } from '@/api';
import { mixins } from 'vue-class-component';
import { PagingMixin } from '@/mixins/paging';
import Consumed from './consumed/consumed.vue';
import {
  CloseStatusEnum,
  PageQueryPlanEnum,
  PrepressChangeColorCraftsEnum,
  PrepressLayoutCraftsEnum,
  PrepressStatusEnum,
  ProjectItemTypeEnum,
  ProjectProductStatusEnum,
  LayoutStatusEnum
} from '@/resource/enum';
import { ApiError, ApiResponse } from '@/api/axios';
import { ImportFile } from '@/views/dialogs';
import ProjectProductDetails from './project-product-details/project-product-details.vue';
import { CustomColumnMixin } from '@/mixins/custom-column';
import { ActiveTab } from '../project-details';
import BatchEditTime from './batch-edit-time/batch-edit-time.vue';
import BatchRevision from './batch-revision/batch-revision.vue';
import { ProjectItemModule } from '@/store/modules/project-item';
import { SortOptions } from '@/api/base';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import { NormalSelectOptions } from '@/resource/model/common';
import ElImageViewer from 'element-ui/packages/image/src/image-viewer';
import { QueryPlanOperationOption } from '@/components/os-query-plan/os-query-plan';
import SelectProjectItem from '@/views/dialogs/select-project-item/select-project-item.vue';

const AddProjectProduct = (): any =>
  import(/* webpackChunkName: "add-project-product" */ './add-project-product/add-project-product.vue');

const EditProjectProduct = (): any =>
  import(/* webpackChunkName: "edit-project-product" */ './edit-project-product/edit-project-product.vue');

/**
 * 更多操作
 */
type Command =
  | 'download'
  | 'close'
  | 'cancelClose'
  | 'export'
  | 'delete'
  | 'complete'
  | 'changeItemType'
  | 'copyAnotherItem';

@Component({
  name: 'ProjectProduct',
  components: {
    Consumed,
    AddProjectProduct,
    EditProjectProduct,
    ImportFile,
    ProjectProductDetails,
    BatchEditTime,
    ElImageViewer,
    BatchRevision,
    SelectProjectItem
  }
})
export default class ProjectProduct extends mixins(PagingMixin, CustomColumnMixin) {
  @Prop({ type: String, required: true })
  public active!: string;

  @Prop({
    type: Boolean,
    required: true
  })
  public isEditProject!: boolean;

  /**
   * 表格key，每次更新表格数据时取反更新key
   */
  public tableKey = true;

  public tableOption: OsTableOption<ProjectProductList> = {
    loading: false,
    data: [],
    fit: true,
    closeAdaptiveHeight: true,
    defaultSort: { prop: 'itemCode', order: 'ascending' },
    maxHeight: 1000
  };

  /**
   * 默认的表格列配置
   */
  public defaultColumnOptions: Array<OsTableColumn<ProjectProductList>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true,
      fixed: true
    },
    {
      prop: 'itemCode',
      label: 'projectProduct.itemCode',
      minWidth: '230px',
      showOverflowTooltip: true,
      fixed: true,
      sortable: 'custom'
    },
    {
      prop: 'pointName',
      label: 'projectProduct.pointName',
      minWidth: '150px',
      showOverflowTooltip: true,
      fixed: true
    },
    {
      prop: 'prepressStatus',
      label: 'projectProduct.prepressStatus',
      minWidth: '100px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        if (!(row as ProjectProductList).prepressStatus) {
          return translation('common.unKnownStatus');
        }
        return translation(`prepressStatus.${PrepressStatusEnum[(row as ProjectProductList).prepressStatus]}`);
      }
    },
    {
      prop: 'archiveStatus',
      label: 'projectProduct.archiveStatus',
      minWidth: '100px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        return translation(`archiveStatus.${ArchiveStatusEnum[(row as ProjectProductList).archiveStatus]}`);
      }
    },
    {
      prop: 'thumbnails',
      label: 'prepress.thumbnails',
      minWidth: '120px',
      showOverflowTooltip: true
    },
    {
      prop: 'layoutPreview',
      label: 'projectProduct.layoutPreview'
    },
    {
      prop: 'platformProductName',
      label: 'projectProduct.platformProduct',
      showOverflowTooltip: true,
      minWidth: '250px'
    },
    {
      prop: 'customerProductName',
      label: 'projectProduct.customerProduct',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'backendCrafts',
      label: 'projectProduct.backendCrafts',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'count',
      label: 'projectProduct.count',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'visibleHeight',
      label: 'projectProduct.visibleSize',
      showOverflowTooltip: true,
      minWidth: '150px',
      formatter: (row: Object): string => {
        return `${(row as ProjectProductList).visibleWidth} × ${(row as ProjectProductList).visibleHeight}`;
      }
    },
    {
      prop: 'finishHeight',
      label: 'projectProduct.finishSize',
      showOverflowTooltip: true,
      minWidth: '150px',
      formatter: (row: Object): string => {
        return `${(row as ProjectProductList).finishWidth} × ${(row as ProjectProductList).finishHeight}`;
      }
    },
    {
      prop: 'prepressPicture',
      label: 'projectProduct.prepressPicture',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'prepressRemark',
      label: 'projectProduct.prepressRemark',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'prepressCrafts',
      label: 'projectProduct.prepressCrafts',
      minWidth: '180px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        if (!(row as ProjectProductList).prepressCrafts) {
          return '--';
        }
        if ((row as ProjectProductList).itemType === ProjectItemTypeEnum.changeColor) {
          return translation(
            `prepressChangeColorCrafts.${PrepressChangeColorCraftsEnum[(row as ProjectProductList).prepressCrafts]}`
          );
        }
        return translation(
          `prepressLayoutCrafts.${PrepressLayoutCraftsEnum[(row as ProjectProductList).prepressCrafts]}`
        );
      }
    },
    {
      prop: 'remark',
      label: 'projectProduct.remark',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'itemType',
      label: 'projectProduct.itemType',
      minWidth: '100px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        const itemType = (row as ProjectProductList).itemType;
        return translation(`projectItemType.${ProjectItemTypeEnum[itemType]}`);
      }
    },
    {
      prop: 'requiredDeliveryTime',
      label: 'projectProduct.requiredDeliveryTime',
      minWidth: '150px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        return dateFormat((row as ProjectProductList).requiredDeliveryTime, 'YYYY-MM-DD HH:mm');
      }
    },
    {
      prop: 'requiredArriveTime',
      label: 'projectProduct.requiredArriveTime',
      minWidth: '150px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        return dateFormat((row as ProjectProductList).requiredArriveTime, 'YYYY-MM-DD HH:mm');
      }
    },
    {
      prop: 'relationCount',
      label: 'projectProduct.relationCount',
      minWidth: '170px'
    },
    {
      prop: 'deliveryCount',
      label: 'projectProduct.deliveryCount',
      minWidth: '170px'
    },
    {
      prop: 'installCount',
      label: 'projectProduct.installCount',
      minWidth: '170px'
    },
    {
      prop: 'invoiceCount',
      label: 'projectProduct.invoiceCount',
      minWidth: '170px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        return `${(row as ProjectProductList)!.invoiceFinishCount ?? 0} / ${(row as ProjectProductList)!.invoiceCount ??
          0}`;
      }
    },
    {
      prop: 'closeStatus',
      label: 'projectProduct.closeStatus',
      minWidth: '150px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        return (row as ProjectProductList).closeStatus === CloseStatusEnum.closed
          ? translation('closeStatus.closed')
          : translation('closeStatus.normal');
      }
    },
    {
      prop: 'manualShutdownFlag',
      label: 'projectProduct.manualShutdownFlag',
      minWidth: '150px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        return (row as ProjectProductList).manualShutdownFlag === 1
          ? translation('common.yes')
          : translation('common.no');
      }
    },

    {
      prop: 'createUserName',
      label: 'project.creator',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'createTime',
      label: 'common.createTime',
      minWidth: '150px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        return dateFormat((row as ProjectProductList & { createTime: string }).createTime, 'YYYY-MM-DD HH:mm');
      }
    }
  ];

  public selectedRows: Array<ProjectProductList> = [];

  /**
   * table上方的表格操作配置
   */
  public operationOptions: Array<OperationOption> = [
    {
      slot: 'start',
      operationType: 'add',
      dynamicHidden: (): boolean => {
        return !this.isEditProject;
      }
    },
    {
      type: 'primary',
      slot: 'start',
      label: 'projectProduct.batchEdit',
      operationType: 'batchEdit',
      icon: 'el-icon-edit',
      permissionCode: 'project:item:correctionTime',
      handleClick: this.batchEdit,
      disabled: true,
      dynamicHidden: (): boolean => {
        return !this.isEditProject;
      }
    },
    {
      slot: 'start',
      operationType: 'prepress',
      dynamicHidden: (): boolean => {
        return !this.isEditProject;
      }
    },
    {
      slot: 'start',
      operationType: 'more'
    }
  ];

  /**
   * table行的操作配置
   */
  public rowOperationOptions: RowOperation<ProjectProductList> = {
    fixed: 'right',
    width: '180px',
    operations: [
      {
        operationType: 'edit',
        type: 'text',
        label: 'button.edit',
        icon: 'el-icon-edit',
        permissionCode: 'project:item:edit',
        handleClick: (row: ProjectProductList): void => {
          this.openEditDialog(row, 'edit');
        },
        dynamicHidden: (rowData: ProjectProductList): boolean => {
          return !(rowData.closeStatus === CloseStatusEnum.normal && this.isEditProject && rowData.invoiceCount === 0);
        }
      },
      {
        operationType: 'revision',
        type: 'text',
        label: 'project.revision',
        permissionCode: 'project:item:reedit',
        handleClick: (row: ProjectProductList): void => {
          this.openEditDialog(row, 'revision');
        },
        dynamicHidden: (rowData: ProjectProductList): boolean => {
          return !(
            ([PrepressStatusEnum.waitForConfirm].includes(rowData.prepressStatus) ||
              ([PrepressStatusEnum.waitComplete].includes(rowData.prepressStatus) &&
                [ArchiveStatusEnum.waitForProduction].includes(rowData.archiveStatus)) ||
              ([PrepressStatusEnum.notMake, PrepressStatusEnum.waitComplete].includes(rowData.prepressStatus) &&
                [ArchiveStatusEnum.alreadyMade].includes(rowData.archiveStatus)) ||
              ([PrepressStatusEnum.waitComplete].includes(rowData.prepressStatus) &&
                [ArchiveStatusEnum.notMake].includes(rowData.archiveStatus))) &&
            rowData.closeStatus === CloseStatusEnum.normal &&
            rowData.makeStatus === 0 &&
            rowData.relationCount === 0 &&
            this.isEditProject
          );
        }
      },
      {
        operationType: 'copy',
        type: 'text',
        label: 'button.copy',
        icon: 'el-icon-document-copy',
        handleClick: (row: ProjectProductList): void => {
          this.openCopyDialog(row);
        },
        dynamicHidden: (): boolean => {
          return !this.isEditProject;
        }
      },
      {
        operationType: 'consumed',
        type: 'text',
        label: 'projectProduct.consumed',
        icon: 'el-icon-document-delete',
        permissionCode: 'project:item:attrition',
        handleClick: this.openConsumedDialog,
        dynamicHidden: (rowData: ProjectProductList): boolean => {
          return !(
            (this.disabledConsumeStatus.includes(rowData.makeStatus) ||
              ([PrepressStatusEnum.waitForConfirm].includes(rowData.prepressStatus) &&
                this.associatedDocument(rowData))) &&
            this.isEditProject
          );
        }
      }
    ]
  };

  public queryItemsOption: Array<
    OsQueryItemOption<
      ProjectProductListQuery & {
        productionFlag: number;
        logisticsFlag: number;
        installFlag: number;
        deliveryStatus: number;
        createUserName: string;
        customerId: number;
        installStatus: number;
        invoiceStatus: number;
        closeStatus: number;
        archiveStatus: number;
      }
    >
  > = [
    {
      type: 'Input',
      field: 'itemCode',
      label: 'projectProduct.itemCode',
      option: {
        placeholder: 'common.inputKeyWord'
      }
    },
    {
      type: 'Input',
      field: 'platformProductName',
      label: 'projectProduct.platformProduct',
      option: {
        placeholder: 'common.inputKeyWord'
      }
    },
    {
      type: 'Input',
      field: 'customerProductName',
      label: 'projectProduct.customerProduct',
      option: {
        placeholder: 'common.inputKeyWord'
      }
    },
    {
      type: 'Input',
      field: 'pointName',
      label: 'projectProduct.pointName',
      option: {
        placeholder: 'common.inputKeyWord'
      }
    },
    {
      type: 'Select',
      field: 'itemType',
      label: 'prepress.itemType',
      option: {
        placeholder: 'common.select'
      },
      optionData: projectProductService.getItemType
    },
    {
      type: 'Select',
      field: 'attritionFlag',
      label: 'projectProduct.isRemake',
      option: {
        placeholder: 'common.select'
      },
      optionData: (): NormalSelectOptions => {
        return [
          {
            label: translation('common.yes'),
            value: 1
          },
          {
            label: translation('common.no'),
            value: 0
          }
        ];
      }
    },
    {
      type: 'Select',
      field: 'urgentOrderFlag',
      label: 'order.urgentOrder',
      option: {
        placeholder: 'common.select'
      },
      optionData: (): NormalSelectOptions => {
        return [
          {
            label: translation('common.yes'),
            value: 1
          },
          {
            label: translation('common.no'),
            value: 0
          }
        ];
      }
    },
    {
      type: 'Select',
      field: 'closeStatus',
      label: 'projectProduct.closeStatus',
      option: {
        placeholder: 'common.select'
      },
      optionData: (): NormalSelectOptions => {
        return [
          {
            label: translation('closeStatus.normal'),
            value: 0
          },
          {
            label: translation('closeStatus.closed'),
            value: 1
          }
        ];
      }
    },
    {
      type: 'Select',
      field: 'invoiceStatus',
      label: 'project.invoiceStatus',
      option: {
        placeholder: 'common.select'
      },
      optionData: (): NormalSelectOptions => {
        return [
          {
            label: translation('project.unfinished'),
            value: 1
          }
        ];
      }
    },
    {
      type: 'Select',
      field: 'installStatus',
      label: 'project.installStatus',
      option: {
        placeholder: 'common.select'
      },
      optionData: (): NormalSelectOptions => {
        return [
          {
            label: translation('project.unfinished'),
            value: 1
          }
        ];
      }
    },
    {
      type: 'Select',
      field: 'deliveryStatus',
      label: 'order.deliveryStatus',
      option: {
        placeholder: 'common.select'
      },
      optionData: (): NormalSelectOptions => {
        return [
          {
            label: translation('project.unfinished'),
            value: 1
          }
        ];
      }
    },

    {
      type: 'Select',
      field: 'platformPrepressFlag',
      label: 'projectProduct.platformPrepressFlag',
      option: {
        placeholder: 'common.select'
      },
      optionData: (): NormalSelectOptions => {
        return [
          {
            label: translation('projectProduct.make'),
            value: 1
          },
          {
            label: translation('projectProduct.doNotMake'),
            value: 0
          }
        ];
      }
    },
    {
      type: 'Input',
      field: 'createUserName',
      label: 'project.creator',
      option: {
        placeholder: 'project.creator'
      }
    },
    {
      type: 'Select',
      field: 'prepressStatus',
      label: 'projectProduct.prepressStatus',
      option: {
        placeholder: 'common.selectStatus'
      },
      optionData: projectProductService.getPrepressStatus
    },
    {
      type: 'Select',
      field: 'archiveStatus',
      label: 'projectProduct.archiveStatus',
      option: {
        placeholder: 'common.selectStatus'
      },
      optionData: projectProductService.getArchiveStatus
    },
    {
      type: 'DateRangePicker',
      field: 'createTime',
      label: 'common.createTime',
      option: {
        rangeSeparator: '~',
        pickerOptions: {
          disabledDate(callbackDateStr: string): boolean {
            const callbackDateTime = new Date(callbackDateStr).getTime();
            const today = new Date();
            const currentDateTime = today.getTime();
            return callbackDateTime >= currentDateTime;
          }
        }
      }
    },
    {
      type: 'DateRangePicker',
      field: 'requiredDeliveryTime',
      label: 'projectProduct.shippingDate',
      option: {
        rangeSeparator: '~'
      }
    },
    /* {
      type: 'Select',
      field: 'productionFlag',
      label: 'order.production',
      option: {
        placeholder: 'common.select'
      },
      optionData: (): NormalSelectOptions => {
        return [
          {
            label: translation('projectProduct.flagOpen'),
            value: 1
          },
          {
            label: translation('projectProduct.flagClose'),
            value: 0
          }
        ];
      }
    }, */
    {
      type: 'Select',
      field: 'makeStatus',
      label: 'projectProduct.status',
      option: {
        placeholder: 'common.selectStatus'
      },
      optionData: projectProductService.getProductMakeStatusEnum
    },
    {
      type: 'Select',
      field: 'logisticsFlag',
      label: 'order.logisticsFlag',
      option: {
        placeholder: 'common.select'
      },
      optionData: (): NormalSelectOptions => {
        return [
          {
            label: translation('projectProduct.flagOpen'),
            value: 1
          },
          {
            label: translation('projectProduct.flagClose'),
            value: 0
          }
        ];
      }
    },
    {
      type: 'Select',
      field: 'installFlag',
      label: 'order.installFlag',
      option: {
        placeholder: 'common.select'
      },
      optionData: (): NormalSelectOptions => {
        return [
          {
            label: translation('projectProduct.flagOpen'),
            value: 1
          },
          {
            label: translation('projectProduct.flagClose'),
            value: 0
          }
        ];
      }
    }
  ];

  /**
   * 页面标识
   */
  public code: number = PageQueryPlanEnum.projectProduct;
  /**
   * 查询方案编辑按钮
   */
  public queryPlanEndOption: Array<QueryPlanOperationOption> = [
    {
      id: 0,
      type: 'primary',
      slot: 'end',
      label: 'button.save',
      permissionCode: 'system:query:template:save',
      handleClick: (): void => {
        (this.$refs.OsQueryPlan as OsQueryPlan).dialogOpened();
      }
    },
    {
      id: 1,
      type: 'text',
      slot: 'end',
      label: 'button.edit',
      permissionCode: 'system:query:template:delete',
      handleClick: (): void => {
        (this.$refs.OsQueryPlan as OsQueryPlan).editDialogOpened();
      }
    }
  ];

  /**
   * 展示确稿预览文件
   */
  public showThumbnails = false;

  /**
   * 要预览的图片列表
   */
  public previewImgList: Array<string> = [];

  /**
   * 展示确稿预览文件
   */
  public showLayoutImg = false;

  /**
   * 要预览的图片列表
   */
  public previewLayoutImgList: Array<string> = [];

  public initialIndex: number = 0;

  /**
   * 控制添加、修改、复制产品明细的的dialog显示隐藏
   */
  public addVisible = false;

  /**
   * 编辑产品dialog是否可见
   */
  public editVisible = false;

  public editType!: 'edit' | 'revision';

  /**
   * 批量改稿
   */
  public batchRevisionVisible = false;

  public editList: Array<ProjectProductList> = [];
  /**
   * 控制耗损的dialog显示隐藏
   */
  public consumedVisible = false;

  /**
   * 控制项次明细dialog的显示隐藏
   */
  public detailsVisible = false;

  public importVisible = false;

  /**
   * 控制批量修改时间dialog显隐
   */
  public batchEditTimeVisible = false;

  /**
   * 项次id（用于编辑）
   */
  public editId = 0;

  /**
   * 项次id（用于详情页）
   */
  public detailsId = 0;

  /**
   * 项次id（用于复制项次）
   */
  public copyId = 0;

  /**
   * 产品项次（用于耗损）
   */
  public consumedRowData: ProjectProductList | null = null;
  /**
   * 项目id，用来对比id是否发生变化，变化时需要重新加载数据
   */
  public oldProjectId = 0;

  /**
   * 调打控制
   */
  public selectProductVisible: boolean = false;

  @InjectReactive()
  public readonly projectId!: number;

  public queryForm: Partial<ProjectProductListQuery> = {
    attritionFlag: undefined,
    customerProductName: '',
    itemCode: '',
    itemType: undefined,
    makeStatus: undefined,
    platformPrepressFlag: undefined,
    platformProductName: '',
    pointName: '',
    prepressStatus: undefined,
    projectId: undefined,
    requiredDeliveryEndTime: undefined,
    requiredDeliveryStartTime: undefined,
    requiredDeliveryTime: [],
    urgentOrderFlag: undefined
  };
  public defaultQueryForm: Partial<ProjectProductListQuery> = {
    attritionFlag: undefined,
    createTime: [],
    createUserName: '',
    customerId: undefined,
    customerProductName: '',
    deliveryStatus: undefined,
    installFlag: undefined,
    installStatus: undefined,
    invoiceStatus: undefined,
    itemCode: '',
    itemType: undefined,
    logisticsFlag: undefined,
    makeStatus: undefined,
    platformPrepressFlag: undefined,
    platformProductName: '',
    pointName: '',
    prepressStatus: undefined,
    productionFlag: undefined,
    requiredDeliveryEndTime: undefined,
    requiredDeliveryStartTime: undefined,
    requiredDeliveryTime: [],
    urgentOrderFlag: undefined
  };

  private sortOptions: SortOptions<ProjectProductList> = this.tableOption.defaultSort!;

  @InjectReactive()
  private readonly customerId!: number;

  @InjectReactive()
  private readonly projectCode!: string;

  private readonly disabledConsumeStatus = [1];

  public activated(): void {
    this.$nextTick(() => {
      if (!isNaN(this.projectId) && this.projectId !== this.oldProjectId) {
        this.oldProjectId = this.projectId;
        this.queryForm = {};
      }

      this.reloadData();
    });
  }

  public created(): void {
    this.oldProjectId = this.projectId;
    this.initColumns(this.defaultColumnOptions, 'project-product');
    this.loadData();
  }

  public reloadData(): void {
    (this.$refs.projectProductTable as OsTable).clearSelection();
    this.selectedRows = [];
    // 重置查询方案
    (this.$refs.OsQueryPlan as OsQueryPlan).id = 0;
    this.loadData();
  }

  public pagingData(): void {
    this.loadData();
  }

  @debounce()
  public queryClick(): void {
    if (this.$route.query.queryPlanName) {
      this.paging.currentPage = 1;
      (this.$refs.OsQueryPlan as OsQueryPlan).selectQueryPlan(this.$route.query.queryPlanName as string);
      return;
    }
    this.loadData();
  }
  /**
   * 执行查询方案
   * @param json
   */
  public queryList(json: string): void {
    this.paging.currentPage = 1;
    Object.assign(this.queryForm, this.defaultQueryForm);
    Object.assign(this.queryForm, JSON.parse(json));
    this.loadData();
  }

  public linkToDetails(projectProduct: ProjectProductList): void {
    this.detailsId = projectProduct.id;
    this.detailsVisible = true;
  }

  public linkToLogistics(): void {
    this.detailsId = 0;
    this.detailsVisible = false;
  }

  public moreClick(command: Command): void {
    switch (command) {
      case 'download':
        this.downloadConfirmFiles();
        break;
      case 'close':
        this.close();
        break;
      case 'cancelClose':
        this.cancelClose();
        break;
      case 'complete':
        this.batchDoneManually();
        break;
      case 'delete':
        this.batchDeleteProjectProduct();
        break;
      case 'export':
        this.exportData();
        break;
      case 'changeItemType':
        this.changeItemType();
        break;
      case 'copyAnotherItem':
        this.copyAnotherItem();
        break;
      default:
        break;
    }
  }

  /**
   * 预览当前行layout
   * @param row 当前预览产品
   */
  public previewLayout(row: ProjectProductList): void {
    const index = this.previewLayoutImgList.findIndex(x => x === row.layoutPreview);
    this.initialIndex = index;
    this.showLayoutImg = true;
  }

  /**
   * 加载layout链接数据
   */
  public loadLayout(): void {
    const list = this.tableOption.data.filter(x => x.layoutStatus === LayoutStatusEnum.generated).map(x => x.id!);
    if(list.length === 0) {
      this.previewLayoutImgList = [];
      return;
    }
    projectProductService
      .previewLayout(list)
      .then(res => {
        if (!res || res.length === 0) {
          return;
        }

        const urlList: string[] = [];
        this.tableOption.data.forEach(item => {
          const layout = res.find(x => x.id === item.id);
          if(layout) {
            item.layoutPreview = layout.url;
            urlList.push(layout.url);
          }
        });

        this.previewLayoutImgList = [];
        this.previewLayoutImgList = urlList;

        this.tableKey = !this.tableKey;
      })
      .catch(error => {
        messageError(error);
      });
  }

  /**
   * 跳转生产订单列表
   * @param rowData
   */
  public linkToProductionOrder(rowData: ProjectProductList): void {
    this.$router.push({
      path: 'production-order',
      query: {
        itemCode: rowData.itemCode
      }
    });
  }


  /**
   * 跳转发货通知单
   * @param rowData
   */
  public linkToDeliveryNotice(rowData: ProjectProductList): void {
    this.$router.push({
      path: 'delivery-notice',
      query: {
        itemId: String(rowData.id)
      }
    });
  }

  /**
   * 跳转安装通知单
   * @param rowData
   */
  public linkToInstallationNotice(rowData: ProjectProductList): void {
    this.$router.push({
      path: 'installation-notice',
      query: {
        itemId: String(rowData.id)
      }
    });
  }

  public linkToBatchCopy(): void {
    this.$router.push({
      path: 'product-batch-copy',
      query: {
        projectId: String(this.projectId)
      }
    });
  }

  public linkToBatchReprint(): void {
    this.$router.push({
      path: 'product-batch-reprint',
      query: {
        projectId: String(this.projectId)
      }
    });
  }

  public getCodeColor(projectProduct: ProjectProductList): 'primary' | 'danger' {
    return projectProduct.attritionFlag === 1 ? 'danger' : 'primary';
  }

  public handleSelectionChange(selectedData: Array<ProjectProductList>): void {
    this.selectedRows = selectedData;
  }

  public handleSortChange(sortOptions: SortOptions<ProjectProductList>): void {
    this.sortOptions = sortOptions;
    this.paging.currentPage = 1;
    this.reloadData();
  }

  public handleAddSuccess(): void {
    this.paging.currentPage = 1;
    this.reloadData();
    this.reloadProjectDetails();
  }

  public handleEditSuccess(): void {
    this.loadData();
    this.reloadProjectDetails();
  }

  public handleAddDialogClosed(): void {
    this.copyId = 0;
  }

  public handleEditDialogClosed(): void {
    this.editId = 0;
  }

  public handleConsumedSuccess(): void {
    this.reloadData();
    this.reloadProjectDetails();
  }

  public downloadTemplate(): void {
    (this.$refs.importDialog as ImportFile).setDownloadLoading(true);
    projectProductService
      .downloadTemplate(this.customerId, this.projectId)
      .then((blob: any) => {
        downloadFileByBlob(`${translation('projectProduct.templateName')}.xlsx`, blob);
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        (this.$refs.importDialog as ImportFile).setDownloadLoading(false);
      });
  }

  public importSuccess(path: string): void {
    projectProductService
      .import({
        filePath: path,
        projectId: this.projectId,
        customerId: this.customerId,
        projectCode: this.projectCode
      })
      .then(() => {
        this.paging.currentPage = 1;
        this.reloadData();
        this.reloadProjectDetails();
        Message.success(translation('dialog.importSuccess'));
        this.importVisible = false;
      })
      .catch((error: ApiResponse<ImportRes>) => {
        handleImportError(error);
        (this.$refs.importDialog as ImportFile).setLoading(false);
      });
  }

  public batchEditTimeSuccess(): void {
    this.reloadData();
  }

  /**
   * 确稿图片预览
   * @param fileName
   * @returns
   */
  public thumbnailsPreview(row: ProjectProductList): void {
    try {
      // 使用findIndex查找对象
      const index = this.tableOption.data.findIndex(item => item.id === row.id);
      this.initialIndex = index;
      this.showThumbnails = true;
    } catch (error) {
      messageError(error);
    }
  }

  public openAddDialog(): void {
    this.addVisible = true;
  }
  public openBatchRevision(): void {
    try {
      this.selectedRows.forEach(rowData => {
        if (rowData.closeStatus === CloseStatusEnum.closed) {
          Message.warning(`【${rowData.itemCode}】${translation('projectProduct.closedCannotEdit')}`);
          throw new Error("End loop");
        }
  
        if (rowData.makeStatus > 0 || rowData.relationCount > 0) {
          Message.warning(`【${rowData.itemCode}】${translation('projectProduct.relatedProductionCannotEdit')}`);
          throw new Error("End loop");
        }
  
        if (!(rowData.prepressStatus === PrepressStatusEnum.waitForConfirm) && 
          !(rowData.archiveStatus === ArchiveStatusEnum.alreadyMade) &&
          !(rowData.prepressStatus === PrepressStatusEnum.waitComplete && [PrepressStatusEnum.notMake, PrepressStatusEnum.waitComplete].includes(rowData.archiveStatus))
        ) {
          Message.warning(`【${rowData.itemCode}】${translation('projectProduct.prepressStatusMismatchEditCondition')}`);
          throw new Error("End loop");
        }
      });
    } catch (error) {
      return;
    }

    this.editList = this.selectedRows;

    this.batchRevisionVisible = true;
  }

  public openImportDialog(): void {
    this.importVisible = true;
  }

  /**
   * 批量确稿
   * @param prepressData 印前数据
   */
  public async batchConfirmManuscript(): Promise<void> {
    try {
      await showWarningConfirm(translation('prepress.confirmManuscriptTip'));
      let ids: Array<number> = [];
      if (this.selectedRows.length === 0) {
        await showWarningConfirm(translation('prepress.aotoOperationTips'));
      } else {
        ids = this.selectedRows
          .filter(
            x => x.prepressStatus === PrepressStatusEnum.waitForConfirm && x.closeStatus === CloseStatusEnum.normal
          )
          .map(x => x.id);
        if (ids.length === 0) {
          Message.warning(translation('prepress.operationNoStandard'));
          return;
        }
      }
      await projectProductService.batchConfirmManuscript(this.projectId, ids);
      this.reloadData();
      this.reloadProjectDetails();
      Message.success(translation('operationRes.operationSuccess'));
    } catch (error) {
      if (error === 'cancel') {
        Message.info(translation('operationRes.cancelOperation'));
        return;
      }
      messageError(error);
    }
  }
  /**
   * 批量修改项次类型
   * @param prepressData 印前数据
   */
  public async changeItemType(): Promise<void> {
    try {
      await showWarningConfirm(translation('project.finishedProductTransferLayoutTip'));
      let ids: Array<number> = [];
      if (this.selectedRows.length === 0) {
        await showWarningConfirm(translation('common.selectminDataTip'));
      } else {
        ids = this.selectedRows
          .filter(
            x =>
              x.makeStatus === 0 &&
              x.itemType === ProjectItemTypeEnum.finishedProduct &&
              x.closeStatus === CloseStatusEnum.normal &&
              x.relationCount === 0 &&
              x.invoiceCount === 0 &&
              x.installCount === 0 &&
              x.deliveryCount === 0
          )
          .map(x => x.id);
        if (ids.length === 0) {
          Message.warning(translation('projectProduct.changeItemTypeWarning'));
          return;
        }
      }
      await projectProductService.changeItemType(ids);
      this.reloadData();
      this.reloadProjectDetails();
      Message.success(translation('operationRes.operationSuccess'));
    } catch (error) {
      if (error === 'cancel') {
        Message.info(translation('operationRes.cancelOperation'));
        return;
      }
      messageErrors(error);
    }
  }

  // 复制项次
  public copyAnotherItem(): void {
    this.selectProductVisible = true;
  }

  /**
   * 批量取消印前单据
   * @param prepressData 印前数据
   */
  public async batchCancelPrepress(): Promise<void> {
    try {
      await showWarningConfirm(translation('prepress.cancelTip'));
      let ids: Array<number> = [];
      if (this.selectedRows.length === 0) {
        await showWarningConfirm(translation('prepress.aotoOperationTips'));
      } else {
        ids = this.selectedRows
          .filter(
            x =>
              [PrepressStatusEnum.waitForProduction, PrepressStatusEnum.notMake].includes(x.prepressStatus) &&
              [ArchiveStatusEnum.waitForProduction, PrepressStatusEnum.notMake].includes(x.archiveStatus) &&
              x.closeStatus === CloseStatusEnum.normal
          )
          .map(x => x.id);
        if (ids.length === 0) {
          Message.warning(translation('prepress.operationNoStandard'));
          return;
        }
      }
      await projectProductService.batchCancelPrepressService(this.projectId, ids);
      ids.forEach(() => {
        ProjectItemModule.addUnPrePressTotal();
      });
      this.reloadData();
      this.reloadProjectDetails();
      Message.success(translation('operationRes.operationSuccess'));
    } catch (error) {
      if (error === 'cancel') {
        Message.info(translation('operationRes.cancelOperation'));
        return;
      }
      messageError(error);
    }
  }

  /**
   * 提交印前
   * @param prepressData 提交制作
   */
  public async batchSubmitPrepress(): Promise<void> {
    try {
      let ids: Array<number> = [];
      if (this.selectedRows.length === 0) {
        await showWarningConfirm(translation('prepress.aotoOperationTips'));
      } else {
        ids = this.selectedRows
          .filter(
            x =>
              [PrepressStatusEnum.new, PrepressStatusEnum.notMake].includes(x.prepressStatus) &&
              [ArchiveStatusEnum.new, ArchiveStatusEnum.notMake].includes(x.archiveStatus) &&
              x.platformPrepressFlag === 1 &&
              x.closeStatus === CloseStatusEnum.normal
          )
          .map(x => x.id);
        if (ids.length === 0) {
          Message.warning(translation('prepress.operationNoSubmit'));
          return;
        }
      }
      const prepressDesignList = this.selectedRows.filter(
        x =>
          x.prepressStatus === PrepressStatusEnum.new &&
          x.platformPrepressFlag === 1 &&
          x.closeStatus === CloseStatusEnum.normal
      );
      const prepressFinishFlagList = this.selectedRows.filter(
        x =>
          x.prepressStatus === PrepressStatusEnum.notMake &&
          x.platformPrepressFlag === 1 &&
          x.closeStatus === CloseStatusEnum.normal
      );
 
      if(prepressDesignList.length > 0 || prepressFinishFlagList.length > 0) {
        await showWarningConfirm(
          translation(
            `您准备提交的单据中${prepressDesignList.length}条需要排版确稿，${prepressFinishFlagList.length}条不需要确稿，请核对下单是否准确`
          )
        );
      }
      this.submitPrepress(ids);
    } catch (error) {
      if (error === 'cancel') {
        Message.info(translation('operationRes.cancelOperation'));
        return;
      }
      messageError(error);
    }
  }

  public async submitPrepress(ids: Array<number>): Promise<void> {
    try {
      await projectProductService.submitProduction(this.projectId, ids);
      ids.forEach(() => {
        ProjectItemModule.delUnPrePressTotal();
      });
      this.reloadData();
      this.reloadProjectDetails();
      Message.success(translation('operationRes.operationSuccess'));
    } catch (error) {
      messageError(error);
    }
  }

  public async loadData(): Promise<void> {
    this.tableOption.loading = true;
    // this.previewImgList = [];
    try {
      Object.assign(this.queryForm, { projectId: this.projectId });
      const res = await projectProductService.getList(this.queryForm, this.paging, this.sortOptions);
      this.tableOption.data = res.data || [];
      this.totalData = res.total || 0;

      this.previewImgList = [];
      this.tableOption.data.forEach(item => {
        this.previewImgList.push(item.thumbnails || 'https://api.minio.primarychina.com.cn/public/no_image.jpg');
      });

      // 加载layout数据
      this.loadLayout();
    } catch (error) {
      messageError(error);
    } finally {
      this.tableOption.loading = false;
    }
  }

  /**
   * 获取Layout状态国际化结果
   * @param data 表格行数据对象
   * @returns 国际化后字符串
   */
  public getLayoutStatus(data: ProjectProductList): string {
    return translation(`layoutStatusEnum.${LayoutStatusEnum[data.layoutStatus]}`);
  }

  private openEditDialog(data: ProjectProductList, editType: 'edit' | 'revision'): void {
    this.editId = data.id;
    this.editVisible = true;
    this.editType = editType;
  }

  private openCopyDialog(data: ProjectProductList): void {
    this.copyId = data.id;
    this.addVisible = true;
  }

  private openConsumedDialog(data: ProjectProductList): void {
    if (!this.disabledConsumeStatus.includes(data.makeStatus)) {
      Message.warning(translation('consumed.checkMakeStatusTips'));
      return;
    }
    this.consumedRowData = data;
    this.consumedVisible = true;
  }

  private closeCheck(rows: Array<ProjectProductList>): boolean {
    const errors: Array<ApiError> = [];
    rows.forEach(x => {
      let message = '';
      if (x.relationCount !== x.relationFinishCount) {
        message = `${message}【生产/外发未完成】`;
      }
      if (x.deliveryCount !== x.deliveryFinishCount) {
        message = `${message}【发货通知单未完成】`;
      }
      if (x.installCount !== x.installFinishCount) {
        message = `${message}【安装通知单未完成】`;
      }
      if (x.closeStatus === CloseStatusEnum.closed) {
        message = `${message}【已关闭】`;
      }
      if (message) {
        errors.push({
          message: `${x.itemCode}: ${message}`,
          level: 1
        });
      }
    });
    if (errors.length > 0) {
      messageErrors(errors);
    }

    return errors.length > 0;
  }

  private async close(): Promise<void> {
    showWarningConfirm(translation('projectProduct.closeConfirmTip'))
      .then(async () => {
        try {
          const filteredRows = this.selectedRows.filter(x => x.closeStatus === CloseStatusEnum.normal);
          if (this.closeCheck(filteredRows)) {
            return;
          }
          const idList: Array<number> = filteredRows.map(x => x.id);

          if (idList.length === 0) {
            Message.warning(translation('projectProduct.closeTip'));
            return;
          }
          await projectProductService.close(idList);

          this.reloadData();
          this.reloadProjectDetails();
          Message.success(translation('operationRes.operationSuccess'));
        } catch (error) {
          messageErrors(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelOperation'));
      });
  }
  private async cancelClose(): Promise<void> {
    showWarningConfirm(translation('projectProduct.cancelCloseConfirmTip'))
      .then(async () => {
        try {
          const filteredRows = this.selectedRows.filter(
            x => x.closeStatus === CloseStatusEnum.closed && x.manualShutdownFlag === 1
          );

          const idList: Array<number> = filteredRows.map(x => x.id);

          if (idList.length === 0) {
            Message.warning(translation('projectProduct.cancelCloseTip'));
            return;
          }
          await projectProductService.cancelClose(idList);
          this.reloadData();
          this.reloadProjectDetails();
          Message.success(translation('operationRes.operationSuccess'));
        } catch (error) {
          messageErrors(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelOperation'));
      });
  }
  private async batchDeleteProjectProduct(): Promise<void> {
    showWarningConfirm(translation('prepress.deleteTip'))
      .then(async () => {
        try {
          const filteredRows = this.selectedRows.filter(
            x =>
              x.makeStatus === 0 &&
              [PrepressStatusEnum.new, PrepressStatusEnum.notMake].includes(x.prepressStatus) &&
              [ArchiveStatusEnum.new, ArchiveStatusEnum.notMake].includes(x.archiveStatus!)
          );

          const idList: Array<number> = filteredRows.map(x => x.id);

          if (idList.length === 0) {
            Message.warning(translation('prepress.operationNoStandard'));
            return;
          }
          await projectProductService.batchDelete(idList);

          filteredRows.forEach(() => {
            ProjectItemModule.delUnDistributeTotal();
            ProjectItemModule.delUnPrePressTotal();
          });

          this.reloadData();
          this.reloadProjectDetails();
          Message.success(translation('operationRes.deleteSuccess'));
        } catch (error) {
          messageErrors(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelDelete'));
      });
  }

  /**
   *
   */
  private associatedDocument(rowData: ProjectProductList): boolean {
    return (
      rowData.relationCount === 0 &&
      rowData.installCount === 0 &&
      rowData.invoiceCount === 0 &&
      rowData.deliveryCount === 0
    );
  }

  @Watch('selectedRows')
  private handleSelectedChanged(value: Array<ProjectProductList>): void {
    const dynamicBtnTypes: Array<string> = ['batchEdit'];
    this.operationOptions.forEach(x => {
      if (dynamicBtnTypes.includes(x.operationType)) {
        x.disabled = value.length === 0;
      }
    });
  }

  @Watch('queryForm.requiredDeliveryTime')
  private handleDeliveryTimeChanged(value: Array<string>): void {
    if (!value || value.length === 0) {
      this.queryForm.requiredDeliveryStartTime = undefined;
      this.queryForm.requiredDeliveryEndTime = undefined;
      return;
    }
    if (value && value.length === 2) {
      this.queryForm.requiredDeliveryStartTime = dateFormat(value[0]);
      this.queryForm.requiredDeliveryEndTime = dateFormat(value[1], 'YYYY-MM-DD') + ' 23:59:59';
    }
  }
  @Watch('queryForm.createTime')
  private handleCreateTimeChanged(value: Array<string>): void {
    if (!value || value.length === 0) {
      this.queryForm.createStartTime = undefined;
      this.queryForm.createEndTime = undefined;
      return;
    }
    if (value && value.length === 2) {
      this.queryForm.createStartTime = dateFormat(value[0]);
      this.queryForm.createEndTime = dateFormat(value[1], 'YYYY-MM-DD') + ' 23:59:59';
    }
  }

  @Watch('active')
  private handleActiveChange(newValue: ActiveTab): void {
    if (newValue === 'product') {
      this.loadData();
    }
  }

  private batchEdit(): void {
    this.batchEditTimeVisible = true;
  }

  /**
   * 批量手动完成项次
   */
  private batchDoneManually(): void {
    showWarningConfirm(translation('projectProduct.doneManuallyTips'))
      .then(async () => {
        try {
          const ids = this.selectedRows
            .filter(x => x.prepressStatus === PrepressStatusEnum.new && x.makeStatus === ProjectProductStatusEnum.new)
            .map(x => x.id);
          if (ids.length === 0) {
            Message.warning(translation('prepressTask.unMatchedRequire'));
            return;
          }
          await projectProductService.batchDoneManually(ids);
          this.reloadData();
          this.reloadProjectDetails();
          Message.success(translation('operationRes.operationSuccess'));
        } catch (error) {
          messageError(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.operationCanceled'));
      });
  }

  private downloadConfirmFiles(): void {
    const availableRows = this.selectedRows;

    if (availableRows.length <= 0) {
      Message.warning(translation('installationOrder.selectNewReceiptsTip'));
      return;
    }
    const idList: Array<number> = [];
    availableRows.forEach(item => {
      if (item.fileId) {
        idList.push(item.fileId);
      }
    });
    try {
      FileService.downloadZip(idList)
        .then(blob => {
          downloadFileByBlob(`$确稿文件_${dateFormat(new Date())}.zip`, blob);
        })
        .catch(error => {
          messageError(error);
        })
        .finally(() => {});
    } catch (error) {
      messageErrors(error);
    }
  }

  private async exportData(): Promise<void> {
    this.tableOption.loading = true;
    try {
      const blob = await projectProductService.export(this.queryForm as ProjectProductListQuery);
      downloadFileByBlob(`${translation('projectProduct.exportFileName')}_${dateFormat(new Date())}.xlsx`, blob);
    } catch (error) {
      messageError(error);
    } finally {
      this.tableOption.loading = false;
    }
  }

  /**
   * 通知父组件重新加载项目详情数据
   */
  @Emit('reload-details')
  private reloadProjectDetails(): void {}
}
